import React from 'react'
import downloadThumbTwo from '../../assets/images/phone-close.png'
import downloadThumb from '../../assets/images/phones.png'

function Understand({ className }) {
  return (
    <>
      <section
        className={`appie-download-3-area pt-100 mb-90 ${className || ''}`}
        id="download"
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="appie-section-title text-center">
                <h3 className="appie-title">Förstå hur din portfölj växer</h3>
                <p>Lär dig mer om teknologin bakom hur din investering växer</p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div
                className="appie-download-3-box mt-30 mr-20 wow animated fadeInLeft"
                data-wow-duration="2000ms"
                data-wow-delay="200ms"
              >
                <div className="content">
                  <h4 className="title">Defi</h4>
                  <p>Du tar del av framtidens blockchain-teknologi</p>
                  <a className="main-btn" href="#">
                    Läs mer om Defi
                  </a>
                </div>
                <div className="thumb text-center">
                  <img src={downloadThumb} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div
                className="appie-download-3-box mt-30 ml-20 wow animated fadeInRight"
                data-wow-duration="2000ms"
                data-wow-delay="400ms"
              >
                <div className="content">
                  <h4 className="title">Belöningar</h4>
                  <p>Dina pengar växer inte endast av kursuppgångar</p>
                  <a className="main-btn main-btn-2" href="#">
                    Se hur det går till
                  </a>
                </div>
                <div className="thumb text-right">
                  <img src={downloadThumbTwo} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Understand
